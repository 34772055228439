import { Obtener,Save ,Editar} from "@/Generico/Conexion";
import { axiosKtb } from "@/plugins/axios";
import { Pallet, PalletActualizarRequest } from "../Calificacion/pallet";
import { DefectoEnviar } from "../KTB/RegistroInformacion/RecepcionListadoQuery";
export class DespachoKTBVerde 
{
    despachoId!:number;
    po!: string;
    haciendaId!: number;
    haciendaName!: string;
    anioCultivo!: number;
    numeroDespacho!: string;
    fechaTumba!: string;
    fechaDespacho!: string;
    tipoMaderaId!: number;
    tipoMaderaName!: string;
    t2K!: number;
    volumen!: number;
    volumenRecibido!:number;
    volumenDescontado!:number;
    plantaId!: number;
    detalles: DespachoKTBVerdeDetalle[]=[];
    calificacion!:Calificacion;
    usuarioId!:string;
    motivo!:string;
  
}

export class DespachoKTBVerdeDetalle 
{
    despachoId!: number;
    pie!:number;
    pulgada!:number;
    id!: number;
    palletId!: string;
    palletIdVisual!: string;
    anchoId!: number;
    espesorId!: number;
    espesorName!: string;
    largoId!: number;
    largoName!: string;
    material!: string;
    numeroPlantilla!: string;
    tipo!: string;
    volumen!: number;
    factorHueco!: number;
    motivo!:string;
}

export class ReenviarDespachoModel
{
    despachoId!:number;
    usuario!:string;
}

export class Calificacion
{
    porcentaje?: number;
    piezas?: number;
    pies?: number;
    piesRechazado?: number;
    largoId?: number;
    id?:number;
    volumen?:number;
    defectos:DefectoEnviar[] =[]
    calificacionAplicada?:number;

    constructor(_id?:number,_largoId?:number
        ,_porcentaje?:number,_piezas?:number,_pies?:number,_piesRechazado?:number,
        _volumen?:number,_calificacionAplicada?:number)
        {
            this.id = _id;
            this.largoId = _largoId;
            this.porcentaje= _porcentaje;
            this.piezas = _piezas;
            this.pies = _pies;
            this.piesRechazado = _piesRechazado;
            this.volumen = _volumen;
            this.calificacionAplicada = _calificacionAplicada;
        }
}

export class DespachoKTBRechazo
{
    despachoId:number;
    usuarioId:string;
    motivo:string;

    constructor(_despachoId:number,_usuarioId:string,_motivo:string)
    {
        this.despachoId = _despachoId;
        this.usuarioId = _usuarioId;
        this.motivo = _motivo;
    }
}

export async function ObtenerDespachoKtb(plantaId:number,estado:string){
   // return Obtener<DespachoKTBVerde>(`despacho/obtenerdespacho/${plantaId}`,axiosKtb);
   return Obtener<DespachoKTBVerde>(`despacho/obtenerdespacho/${estado}${plantaId}`,axiosKtb);
}

export async function RechazoKtb(request:DespachoKTBRechazo)
{
    return Editar<DespachoKTBRechazo>('despacho/rechazar',request,axiosKtb);
}

export async function RegistrarDespacho(request:DespachoKTBVerde)
{
    return Save<DespachoKTBVerde>('despacho/aprobardespacho',request,axiosKtb);
}

export async function EliminarModificarPallet(request:PalletActualizarRequest)
{
    return Editar<PalletActualizarRequest>('despacho/modificarpallet',request,axiosKtb);
}

export async function ReenviarDespacho(request:ReenviarDespachoModel)
{
    return Editar<ReenviarDespachoModel>('despacho/reenviodespacho',request,axiosKtb);
}