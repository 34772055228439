






























import { Pallet, PalletActualizarRequest } from '@/entidades/Calificacion/pallet';
import { DespachoKTBVerde,DespachoKTBVerdeDetalle,ObtenerDespachoKtb,EliminarModificarPallet, ReenviarDespachoModel, ReenviarDespacho } from '@/entidades/MaderaVerde/KtbDespacho';
import { Planta } from '@/entidades/Maestro/Planta';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component} from 'vue-property-decorator';
import {Action, Mutation, State} from 'vuex-class';
@Component({
    name: 'DespachoRechazado',
    components:{
        'tabla' : () =>import('@/components/General/TablaGeneralFinal.vue'),
         'editPallet':() =>import('@/components/MaderaVerde/Operacion/EditDeletePallet.vue')
    }
})
export default class DespachoRechazado extends Vue
{
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Fecha",sortable:true,value:'fechaDespacho'},
            {text:"Fecha Tumba",sortable:true,value:'fechaTumba'},
            {text:"# Despacho",sortable:true,value:'numeroDespacho'},
            {text:"Tipo Madera",sortable:true,value:'tipoMaderaName'},
            {text:"Hacienda",sortable:true,value:'haciendaName'},
            {text:"Año Cultivo",sortable:false,value:'anioCultivo'},
            {text:"T2K",sortable:false,value:'t2K'},
            {text:"Volumen",sortable:false,value:'volumen'},
            {text:"Motivo",sortable:false,value:'motivo'},
            {text:"Accion",sortable:false,value:'actions'}                       
    ];
     headerDetalle:{text:string,sortable:boolean,value:string}[] =
    [
        {text:"# Pallet",sortable:true,value:'palletId'},
        {text:"Espesor",sortable:true,value:'espesorName'},
        {text:"Largo",sortable:true,value:'largoName'},
        {text:"Ancho",sortable:true,value:'anchoId'},
        {text:"Material",sortable:true,value:'material'},
        {text:"Factor Hueco",sortable:true,value:'factorHueco'},
        {text:"# Plantilla",sortable:true,value:'numeroPlantilla'},
        {text:"Volumen",sortable:true,value:'volumen'},
        {text:"Motivo",sortable:false,value:'motivo'}, 
        {text:"Accion",sortable:false,value:'actions'}                    
    ];

    listado:Array<DespachoKTBVerde> =[];
    ruta:string ="?Estado=R&PlantaId="
    modalDetalle:boolean=false;
    nameComponent:string="";
    detalleItem:Array<DespachoKTBVerdeDetalle> = [];
    async obtenerListado()
    {
        try
        {
            this.changeLoading(new Loading(true,"Cargando informacion...Espere por favor"));
            var rsp = await ObtenerDespachoKtb(this.plantas[0].plantaId,this.ruta);
            if(rsp.isSuccess == true)
            {
                this.listado = rsp.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
            }
        }
        catch(error)
        {
            console.log(error);
        }
        finally 
        {
            this.changeLoading(new Loading(false,""));
        }
    }

     verDetalle(item:DespachoKTBVerde)
    {
        this.detalleItem = item.detalles;
        this.nameComponent = "editPallet";
        this.modalDetalle = true;
    }
    async eliminarPallet(pallet:DespachoKTBVerdeDetalle,accion:string)
    {
        var pall = new PalletActualizarRequest();
        pall.accion = accion;
        pall.palletId = pallet.id;
        pall.espesorId = pallet.espesorId;
        pall.largoId = pallet.largoId;
        pall.usuarioId = this.usuarioId;
        pall.despachoId = pallet.despachoId;
        pall.motivo =  pallet.motivo;
        pall.numPlantilla = Number(pallet.numeroPlantilla);
        pall.volumen = pallet.volumen;
        try
        {
            this.changeLoading(new Loading(true,"Eliminando Pallet"));
            let rsp = await EliminarModificarPallet(pall);
        
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
                this.limpiar();
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
            }

        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    async reenviarDespacho(item:DespachoKTBVerde)
    {
        try
        {
            this.changeLoading(new Loading(true,"Reenviando despacho a  Madera Verde"));
            var despacho =  new ReenviarDespachoModel();
            despacho.despachoId = item.despachoId;
            despacho.usuario = this.usuarioId;
            let rsp = await ReenviarDespacho(despacho);
            console.log(rsp);
            if(rsp.isSuccess == true)
            {
                this.limpiar();
                this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",2000));
                 
            }
            else
            {
                 this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",2000));
                 return;
            }
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(error,true,"danger",2000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    async limpiar()
    {
        this.listado = [];
        this.detalleItem = [];
        this.nameComponent = "";
        this.modalDetalle = false;
        await this.obtenerListado();
    }

     get propiedades()
    {
        if(this.nameComponent == "editPallet")
        {
            return {'headers' : this.headerDetalle, 'busqueda' :false, 'items' : this.detalleItem};
        }
    }

    mounted() {
        this.obtenerListado();
    }
}
